
import { Component, Prop, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
    components: {
        LsDialog
    }
})
export default class GoodsIsGather extends Vue {
    $refs!: { lsDialog: any }
    @Prop() title?: string
    checking = true
    closeDialog() {
        this.checking = false
        this.$refs.lsDialog.close()
    }
    openDialog() {
        this.checking = true
        this.$refs.lsDialog.open()
    }
}
